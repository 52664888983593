
let message: string = 'Hello World from TS';
console.log(message);


class Entry {

	main() {
		document.getElementById('message').innerHTML = 'Hello from TS';
	}
}
